export const environment = {
  appName: 'bookingpad',
  name: 'release',
  production: true,
  ndcApiEndpoint: 'https://proxy.airgateway.live/',
  ndcApiEndpointV2: 'https://api.airgateway.live/v2.0/',
  keycloakUrl: 'https://auth.airgateway.live',
  hashVersion: 'e0c96716',
  coviewAppKey: 'UtgDnY7wWIk',
  domain: '.live',
  default: {
    logoName: 'bookingpad.svg',
    title: 'BookingPad :: NDC-enabled Booking Tool',
    showSearch: true,
    showOrders: true,
    showSubagencies: true,
    showFooter: true,
    showPrivacy: true,
    showCoView: true,
    showSettings: true,
    showCorporates: true,
  },
  frontendDependencies: [{
    logoName: 'apglogo.png',
    subDomain: 'apg.bookingpad.net',
    title: 'Orchestra Orders Tool',
    showSearch: false,
    showOrders: true,
    showSubagencies: false,
    showFooter: false,
    showPrivacy: false,
    showCoView: false,
    showSettings: false,
    showCorporates: false,
  }]
};
